import React, { useState, useRef } from "react";
import { history } from "../helpers/history";
import axios from '../api/index';
import moment from 'moment';
import {
  useParams,
  generatePath
} from "react-router-dom";


class Profile extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      results: [],
      loading: false,
      user: {},

    }
  }
  
  componentDidMount() {
    this.setState({
      loading: true,
    });
    const tokenString = sessionStorage.getItem('token');
    const userToken = JSON.parse(tokenString);
    const userString = sessionStorage.getItem('user');
    const currentUser = JSON.parse(userString);
    
    if (!currentUser) {
      this.setState({
        currentUser: null,
      });
      localStorage.removeItem("user");
      history.push("/home");
      sessionStorage.clear();
    }
    if (!userToken) {
      this.setState({
        currentUser: null,
      });
      localStorage.removeItem("user");
      history.push("/home");
      sessionStorage.clear();
  }
  this.setState({
    loading: true,
    user: currentUser,
  });
  this.fetchdata();
}

fetchdata()
{
  this.setState({
      loading: true,
    });
    return axios({
      url: '/api/home',
    })
    .then(res => {
      this.setState({
        loading: false,
        results: res.data.videos,
      });
    })
    .catch(err => {
      this.setState({
        loading: false,
      });
    });
  }
  changeDialog = (e, index) => {
    e.preventDefault()
    this.setState({
      activeIndex: index,
    })
  }
  
  goToSession(video)
  {
  
  
    
    sessionStorage.setItem('video', video.id+'');

  
    history.push(`/session/${video.id}`);

    // history.push("/session");
    window.location.reload();

  }


  renderItem(result, index)
  {
    var now = new Date();
    now.setHours(0,0,0,0);

  if(result.active === "0")
  {
    return(
<div className="card text-center">
         <div className="card-header"> {result.title} </div>
         <div className="card-body"> 
          :: موعد المحاضرة يوم 
         <div Style="width:40%; margin:auto" className="alert alert-info"> {moment(result.date_available).format('DD-MM')} </div> 
         <br></br>
          <div className="row text-center" > 
          <div className="col-12">
            <p className="text">
           المتكلم: <br></br>  <div Style=" margin:auto" className="alert alert-info">{result.speaker }</div>   
           </p>
          </div>
          <div className="col-6">
          </div>
          </div>
          </div>
        </div>
    )
  }
  else{
    return (
      <div key={index} 
      >
        <div className="card text-center">
         <div className="card-header"> {result.title} </div>
         <div className="card-body"> 
          :: موعد المحاضرة يوم 
         <div Style="width:40%; margin:auto" className="alert alert-info"> {moment(result.date_available).format('DD-MM')} </div> 
         <br></br>
          <div className="row text-center" > 
          <div className="col-6">
            <p className="text">
           المتكلم: <br></br>  <div Style=" margin:auto" className="alert alert-info">{result.speaker }</div>   
           </p>
          </div>
          <div className="col-6">

        {result.watched === true ? (
          <p>
           لقد قمت بمشاهدة الفيديو 
           <br></br>
           <svg xmlns="http://www.w3.org/2000/svg" width="10%" height="10%" fill="green" class="bi bi-check2-circle" viewBox="0 0 16 16">
  <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z"/>
  <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z"/>
</svg>
<br></br>
          حالة الامتحان
          <br></br>
          <h4> {result.quizzes[0].grade} </h4> 
          </p>
          )
          :
          (
          <p>
            لم تشاهد الفيديو بعد 
            <br></br>

            <svg xmlns="http://www.w3.org/2000/svg" width="10%" height="10%"  fill="#F29339" class="bi bi-hourglass-split" viewBox="0 0 16 16">
  <path d="M2.5 15a.5.5 0 1 1 0-1h1v-1a4.5 4.5 0 0 1 2.557-4.06c.29-.139.443-.377.443-.59v-.7c0-.213-.154-.451-.443-.59A4.5 4.5 0 0 1 3.5 3V2h-1a.5.5 0 0 1 0-1h11a.5.5 0 0 1 0 1h-1v1a4.5 4.5 0 0 1-2.557 4.06c-.29.139-.443.377-.443.59v.7c0 .213.154.451.443.59A4.5 4.5 0 0 1 12.5 13v1h1a.5.5 0 0 1 0 1h-11zm2-13v1c0 .537.12 1.045.337 1.5h6.326c.216-.455.337-.963.337-1.5V2h-7zm3 6.35c0 .701-.478 1.236-1.011 1.492A3.5 3.5 0 0 0 4.5 13s.866-1.299 3-1.48V8.35zm1 0v3.17c2.134.181 3 1.48 3 1.48a3.5 3.5 0 0 0-1.989-3.158C8.978 9.586 8.5 9.052 8.5 8.351z"/>
</svg>
          </p>)
        }
          </div>
          </div>
          </div>
       <div className="card-footer">
       <button className="btn btn-primary btn-block"
          onClick={() => {
            this.goToSession(result)
          }}
            >
                <span>
                اذهب الى المحاضرة
                </span>  
                  </button>
       </div>
        </div>
      </div>
    )
  }
}

  render() {
    const {
      user,
      results,
    } = this.state
    return(
      <div className="container">
      <header className="jumbotron" style={{ textAlign: 'center'}}>
        <h3>
          <strong> آهلا {user && user.name && user.name}</strong> 
        </h3>
      </header>
      <header className="alert alert-info" style={{ textAlign: 'center'}}>
        <h3>
          <strong> هنا ستجد جميع المحاضرات و سيظهر  زر "اذهب الي المحاضرة" في وقت المحاضرة </strong> 
        </h3>
      </header>
      <p>
      </p>
      <ul>
        <div style={{overflowY: 'scroll' }}>
          {results &&
              results.length > 0 &&
              results.map((result, index) => {
                return this.renderItem(result, index);
              })}
        </div>
      </ul>
    </div>
    )
  }

}
export default Profile;
