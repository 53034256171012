import React, { useState, useEffect } from "react";

import UserService from "../services/user.service";
import logo from './logo.png'; // Tell webpack this JS file uses this image
import image from './image.png'; // Tell webpack this JS file uses this image
import { Link} from "react-router-dom";

const Home = () => {
  const [content, setContent] = useState("");

  useEffect(() => {
    UserService.getPublicContent().then(
      (response) => {
        setContent(response.data);
      },
      (error) => {
        const _content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();

        setContent(_content);
      }
    );
  }, []);

  return (
    <div className="container"
    style={{textAlign: 'center'}}
    >
      <header className="jumbotron">
        <div style={{textAlign: 'center'}}>
            <img src={logo} alt="Logo"  style={{width: 200, height: 200,  }}/>
        </div>
        <br/>
        <h3>كنيسة الشهيد العظيم مارمرقس الرسول بمصر الجديدة </h3>
        <h3>دورة المخطوبين ال ٥٤</h3>
      </header>
     <div>
{/* 
      <div className="jumbotron">
      <label style={{textAlign: 'center'}} htmlFor="password">تسجيل جديد</label>
      <br/>
        <button className="btn btn-primary btn-block" >
          <span>تسجيل جديد</span>
        </button>
      </div> */}

      {/* <div className="jumbotron">
      <label style={{textAlign: 'center'}} htmlFor="password">دخول</label>
      <br/>
        <button className="btn btn-primary btn-block" >
          <span>دخول</span>
        </button>
      </div> */}

 

      <div className="jumbotron">
      <span style={{textAlign: 'right'}}>
      {/*   <li dir="rtl"> اخر موعد للدفع بعد ٣ أيام من تاريخ التسجيل.</li>
        </span>  
        <span style={{textAlign: 'right'}}>
        <li dir="rtl">تبدأ المحاضرات يومياً في مواعيد الدورة المعلنة الساعة ٧،٠٠ مساء بواقع محاضرتين يوميا بينهم استراحة قصيرة
        </li>
        </span>  
        <span style={{textAlign: 'right'}}>
        <li dir="rtl">المحاضرات بالحضور بالكنيسة ويمكن الحضور اونلاين على موقع دورة المخطوبين والدخول من خلال اسم المستخدم وكلمة المرور الذين تم ارسالهم سابقاً.
        </li>
        </span>  
        <span style={{textAlign: 'right'}}>
        <li dir="rtl">في حالة الحضور اونلاين لا يتم اثبات الحضور الا بعد مشاهدة المحاضرة كاملة ويرجى عدم اغلاق المتصفح او غلق الصوت حتى يتمكن النظام من اثبات الحضور.
        </li>
        </span>  
        <span style={{textAlign: 'right'}}>
        <li dir="rtl"> بعد الحضور سواء بالكنيسة او اونلاين سيتم اتاحة الفرصة لك لتقوم بحل الامتحان الخاص بكل محاضرة على موقع دورة المخطوبين ونتيجته تكون اما نجاح او رسوب ويمكن في حالة الرسوب إعادة مشاهدة المحاضرة وإعادة حل الامتحان الا محاضرات اليوم الأخير لا يمكن اعادتهم.
        </li>
        </span>  
        <span style={{textAlign: 'right'}}>
        <li dir="rtl">لتحصل على الشهادة الخاصة بالدورة يجب حضور المحاضرات كاملة والنجاح في كل الامتحانات.
        </li>
        </span>  
        <span style={{textAlign: 'right'}}>
        <li dir="rtl">يغلق باب تسجيل الأسماء بالكنيسة بعد بدء المحاضرة ب ١٠ دقائق على اقصى حد.       
        </li>
         </span>  
        <span style={{textAlign: 'right'}}>
        <li dir="rtl">فور طباعة الشهادات يتم ارسال رسالة بموعد استلامها من سكرتارية الاباء بالكنيسة.
        </li>
        </span>  
        <span style={{textAlign: 'right'}}><li dir="rtl">
         لأي استفسارات أخرى التواصل من خلال رسائل الواتس اب فقط على الرقم: <span dir="ltr"> &nbsp;  01019966994 </span> او رسائل البريد الالكتروني على <span dir="ltr"> &nbsp;  engaged@stmarkos.org </span> 
والرب يبارك حياتكم و يملأها بكل فرح و سرور       
</li> */}
      <button className="btn btn-primary btn-lg">
       <Link style={{color:'white'}} to="/register">
             تسجيل جديد
            </Link>
       </button>
 </span>  
      </div>
      <div className="jumbotron" >
        <img src={image} alt="دورة المخطوبين ال ٥٤" style={{textAlign: 'center',height: 300,  }} />
      </div>
     </div>
    </div>
  );
};

export default Home;
