import React, { useState, useRef , useEffect } from "react";
import { history } from "../helpers/history";
import axios from '../api/index';
import YouTube from 'react-youtube';
import { useParams} from 'react-router-dom';


class Session extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      results: [],
      loading: false,
      user: {},
      video: {},
      startPlay: 0,
      playtime: 0,
      pausePlay: true,
      seconds:'',
      setSeconds:'',
      params:{},
      watchedflag:false
      
    }
    
  }
  

  componentDidMount() {
    this.interval = setInterval(() => this.checkVideo(), 300000);
    
    const id = this.props.match.params.id;

    
    this.setState({
      loading: true,
      
    });
    
    let tokenString = sessionStorage.getItem('token');
    let userToken = JSON.parse(tokenString);
    let userString = sessionStorage.getItem('user');
    let currentUser = JSON.parse(userString);
    
    if (!currentUser) {
      this.setState({
        currentUser: null,
      });
      localStorage.removeItem("user");
      history.push("/home");
      sessionStorage.clear();
      window.location.reload();
    }
    if (!userToken) {
      this.setState({
        currentUser: null,
      });
      localStorage.removeItem("user");
      history.push("/home");
      sessionStorage.clear();
      window.location.reload();
  }
  
  this.setState({
    loading: true,
    user: currentUser,
    
  });
  
 
    this.fetchdata(id);
 
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }

  fetchdata(id)
  {
    let video = sessionStorage.getItem('video');

  console.log(this.id);
    this.setState({
      loading: true,
    });
    return axios({
        url: `/api/video/${id}`,
    })
    .then(res => {
        this.setState({
        loading: false,
        video: res.data.video,
      });
    })
    .catch(err => {
      this.setState({
        loading: false,
      });
      if(err.response.status)
      {
        this.setState({
        video: false,
      });
      }
    });
  }
  changeDialog = (e, index) => {
    e.preventDefault()
    this.setState({
      activeIndex: index,
    })
  }

  startQuiz(quiz)
  {
    sessionStorage.setItem('quiz', quiz[0].id);
    history.push("/quiz");
    window.location.reload();
  }
  onReady = (value) =>
  {
    //TODO:
  }
  onPlay = (value) =>
  {    
    this.setState({
      startPlay: new Date(),
      pausePlay: false,
    });
  }

  onPause = (value) =>
  {    
    this.setState({
      pausePlay: true,
      playtime: this.state.playtime + ( new Date().getTime() - this.state.startPlay),
      startPlay: 0,
    });
    console.log('before sending video');
    console.log(this.state.playtime);
    if(this.state.playtime >= 1800000 && !this.state.watchedflag)
    {
      
      this.wathedVideo();
    }
  }

  onEnd = (value) =>
  {
    this.setState({
      pausePlay: true,
      playtime: this.state.playtime + (new Date().getTime() - this.state.startPlay),
      startPlay: 0,
    });
    if( this.state.playtime >= 1800000)
    {
      this.wathedVideo();
    }
  }

checkVideo(){
  console.log('hi deep');

    if(this.state.pausePlay === false)
    {
      console.log('hi deep');
      this.onPause();
      this.onPlay();
    }
}

  wathedVideo()
  {
    this.setState({
      loading: true,
    });
    const { user,video} = this.state
    return axios({
        url: `/api/watched`,
        body: {
          user: user.id,
          video: video.id
        }
    })
    .then(res => {

        if(res.data[0] == 'success')
        this.setState({watchedflag: true});

        this.setState({
        loading: false,
        playtime: 0,
      });
    })
    .catch(err => {
      this.setState({
        loading: false,
      });
    });
  }
// Window.addEventListener('beforeunload', function (e) {
//     // Cancel the event
//     e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
//     this.wathedVideo();
//    return e.returnValue = '';
//   });
  render() {
    const {
      video,
    } = this.state
    return(
      
      <div class="container-fluid" style={{ backgroundColor: '#fffafb', height: '100%', width: '100%'}}>
      {!video && (
        <h1 class= "alert alert-danger text-center">...حدث خطآ! من فضلك اعد تحميل الصفحة او التأكد من الرابط</h1>
        )}

        <div style={{paddingTop: '1%',}} class="container-fluid">
        {video && video.title && (
            <h5 class="display-4 text-center  d-md-block" style={{color: '#646ecb'}}>
            {video.title}
            </h5>
        )}
        {video && video.speaker && (
            <h5 class="display-5 text-center  d-md-block" style={{color: '#646ecb'}}>
            {video.speaker}
            </h5>
        )}

        <div class="row justify-content-around">
        <div class="col-lg-7 col-md-12">
        <div class="embed-responsive embed-responsive-16by9">
           
        <YouTube
            videoId={video.link}                  // defaults -> null
            id={video.link}                       // defaults -> null
            onReady={this.onReady}                    // defaults -> noop
            onPlay={this.onPlay}                     // defaults -> noop
            onPause={this.onPause}                    // defaults -> noop
            onEnd={this.onEnd}                      // defaults -> noop
            onError={this.onError}                    // defaults -> noop
            onStateChange={this.onStateChange}              // defaults -> noop
          />

        </div>
        <div class="card">
        <div dir="rtl"  style={{backgroundColor: '#646ecb'}}  class="card-header text-center text-white">
        لو عندك سؤال في المحاضرة ابعته هنا &#128071;
        </div>
        <div class="card-body">
        <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSfHeIvCTR1YGHfcGtQR9VzSN0hRYsd5-o502vIKwE07GDCtzA/viewform?embedded=true" width="100%" height="425" frameborder="0" marginHeight="0" marginWidth="0">Loading…</iframe>
        </div>
        </div>
        </div>
        <div class="col-lg-5 col-md-12">
        {!video.watched   && (
      
                  <button className="btn btn-primary btn-lg btn-block btn-warning"
                  onClick = {() => {
                    this.checkVideo()
                    alert("سيكون الامتحان متاح لك عند مشاهدة الفيديو بالكامل .. عندما ينتهي الفيديو قم بتوقيف الفيديو(pause video) و اعادة تحميل الصفحة (reload page) و سيظهر زر ابدأ الامتحان بالاخضر اذا قمت بمشاهدة الفيديو بالكامل")
                  }}>
                        ابدا الامتحان
                   
                  </button>
         
            )}
        {video.watched   && (
                  <button className="btn btn-success btn-lg btn-block"
                    onClick={() => {
                      this.startQuiz(video.quizzes)
                    }}>
                      <span>
                        ابدا الامتحان
                      </span>  
                  </button>
            )}
  
          <div class="card text-center">
          <div style={{backgroundColor: '#646ecb'}} class="card-header text-center text-white">ملفات المحاضرة</div>
          <div class="card-body">
          {video && video.pdf && (
              <iframe src={video.pdf} width="100%" height="700px"></iframe>
              )}
          </div>
          </div>
        </div>
        </div>
        </div>
    </div>
    )
  }


}


// window.addEventListener('beforeunload', function (e) {
//   // Cancel the event
//   e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
//   // Chrome requires returnValue to be set
//   e.returnValue = '';
//   return 1;

// });




export default Session;
