import React from "react";
import { history } from "../helpers/history";
import axios from '../api/index';
import logo from './52logo.png'; // Tell webpack this JS file uses this image

const required = (value) => {
    if (!value) {
      return (
        <div className="alert alert-danger" role="alert">
          !هذا الحقل مطلوب
        </div>
      );
    }
  };

class Quiz extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      results: [],
      loading: false,
      user: {},
      video: {},
      message: '',
    }
  }
  

  componentDidMount() {
    this.setState({
      loading: true,
    });
    let tokenString = sessionStorage.getItem('token');
    let userToken = JSON.parse(tokenString);
    let userString = sessionStorage.getItem('user');
    let currentUser = JSON.parse(userString);

    if (!currentUser) {

      this.setState({
        currentUser: null,
      });
      localStorage.removeItem("user");
      history.push("/home");
      sessionStorage.clear();
      window.location.reload();
    }
    if (!userToken) {
      this.setState({
        currentUser: null,
      });
      localStorage.removeItem("user");
      history.push("/home");
      sessionStorage.clear();
      window.location.reload();
  }
  
  this.setState({
    loading: true,
    user: currentUser,
  });
    this.fetchdata();
  }

  fetchdata()
  {
    let quiz = sessionStorage.getItem('quiz');
    this.setState({
      loading: true,
    });
    return axios({
        url: `/api/quiz/${quiz}`,
    })
    .then(res => {
       console.log(res); 
        let questions = res.data.quiz;
        this.setState({
        loading: false,
        questions: res.data.quiz,
        answersFinal: questions.map(question => ({
            questionsID: question.id,
            answer: null,
        }))
        
      });
    })
    .catch(err => {
      if(err.response.status == 404 ){
      this.setState({
        message: "لا يوجد امتحان لهذه المحاضرة",
        questions:false,
        loading: false,
      });
    }
    });
  }
  changeDialog = (e, index) => {
    e.preventDefault()
    this.setState({
      activeIndex: index,
    })
  }

  onChangeAnswer =  (answer, question) => 
  {
    const { answersFinal } = this.state;
    let final = answersFinal.map(x => ({
      questionsID: x.questionsID,
      answer: x.questionsID === question.id ? answer.target.value : x.answer
    }));
    this.setState({
      answersFinal: final,
    })
  }

  submit()
  {
    this.setState({
      loading: true,
    });
    const { answersFinal, user} = this.state
     answersFinal.forEach(element => {
       if(element.answer === null)
       {
        this.setState({
          message: '!من فضلك اجب علي جميع الاسئلة',
        });
         return;
       }
     });
    return axios({
        url: `/api/answer`,
        body: {
          user: user.id,
          answers: answersFinal,
          quiz: sessionStorage.getItem('quiz')
        },
        method: 'POST'
    })
    .then(res => {
      this.setState({
        loading: false,
      });
      sessionStorage.setItem('quiz', null);
      history.push("/profile");
      window.location.reload();
    })
    .catch(err => {
      this.setState({
        loading: false,
      });
    });
  }
  
  renderQuestion(question, index)
  {
      return(
          <div className="card card-container"
            key={index}>
              <p>
                  {index+1}. {question.question}
              </p>
              
              <p>
              <select 
              key={index}
                onChange={
                    answer => this.onChangeAnswer(answer, question)}
                validations={[required]}
                >
                   <option key ={0} value={0}>{'اختر الاجابة الصحيحة'}</option>
                    {question &&
                  question.options &&
                  question.options.length >0 &&
                  question.options.map((option, idx) => 
                  (
                    <option key ={idx} value={option.id}>{option.option}</option>
                  )
                )}
                </select>
              </p>
          </div>
      )
  }
  render() {
    const {
      questions,
      loading,
      message,
    } = this.state
    return(
      <div class="jumbotron text-center container" style={{ textAlign: 'center'}}>
      {!questions  && !message && (
        <p>...حدث خطآ! من فضلك اعد تحميل الصفحة</p>
       )}
        {questions&&
            questions.length > 0 &&
            questions.map((question, index) => {
              return this.renderQuestion(question, index);
            })}

         {message && (
            <div className="form-group">
              <div className="alert alert-danger" role="alert">
                {message}
              </div>
            </div>
          )}

        {questions  &&   (<button className="btn btn-primary btn-block"  onClick={() => {this.submit()}}>
              {loading && (
              <span className="spinner-border spinner-border-sm"></span>
            )}
            <span>
              انتهاء الامتحان
            </span>  
          </button>) }


    </div>
    )}
}
export default Quiz;
