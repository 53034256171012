import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from 'react-router-dom';
import logo from './logo.png'; // Tell webpack this JS file uses this image

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import axios from '../api/index';

import { login } from "../actions/auth";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const Payment = (props) => {
    console.log('here ana fl payent');
  const form = useRef();
  const checkBtn = useRef();

 
  const [loading, setLoading] = useState(false);

  const { isLoggedIn } = useSelector(state => state.auth);
  const { message } = useSelector(state => state.message);

  return (
    <div className="col-xl">
      <div className="card card-container-lg">
        <div style={{textAlign: 'center'}}>
          <img src={logo} alt="Logo"  style={{width: 200, height: 200,  }}/>
        </div>

      <br/>
      <br/>
        <span dir="rtl" style={{textAlign: 'center' }}>
        اهلاً ومرحباً بكم في الدورة الرابعة والخمسون لإعداد المخطوبين و المقبلين على الزواج 
في الفترة من الاثنين 13 يونيو الي الخميس 16 يونيو 2022 بكنيسة مارمرقس بمصر الجديدة.
يتم تفعيل حسابك بعد خلال ٢٤ ساعة من دفع مبلغ الاشتراك (١٥٠ جنيه للفرد) من خلال الطرق الاتية: 
        </span>
        <br/>
        <span style={{textAlign: 'right'}}>
        الدفع عند سكرتارية الاباء بالكنيسة يومياً من ١٠ صباحاً الي ٨ مساءً وابلاغهم بالاسم ورقمك الكودي الذي تم ارساله في رسالة واتس اب وتليفون محمول.
        </span>  
        <br/>
        <span style={{textAlign: 'right'}}>
       تحويل الاشتراك على فودافون كاش رقم  :    <span dir="ltr"> &nbsp;  01019966994 </span>
           يمكن التحويل من خلال أي رقم فودافون به خدمة فودافون كاش. 
        يمكن التحويل من محافظ الكترونية أخرى او فوري او ماكينات الدفع الاخرى، ولكن يجب ارسال رسالة تأكيد قبل التحويل بنصف ساعة ليتمكن احد الخدام من تأكيد استقبال الاشتراك على نفس الرقم.
        </span>
<br/>
        <span style={{textAlign: 'right'}}>
الدفع عن طريق بطاقات الائتمان على موقع الكنيسة على الرابط <span dir="ltr"> &nbsp;  https://donation.stmarkos.org &nbsp; </span> :  وعمل حساب للتبرع و اختيار الخدمة (دورة اعداد المخطوبين و المقبلين على الزواج) و دورية التبرع مرة واحدة.        
        </span>  
        <br/>
        <span dir="rtl" style={{textAlign: 'right'}}>
         في حالة الدفع عن طريق فودافون كاش او فوري او بطاقات الائتمان رجاء ارسال رسالة علي الواتس اب الخاص بالدورة بها التالي: 
         <li>الاسم</li>
         <li>الرقم الكودي</li>
         <li>تاريخ ووقت المعاملة والمبلغ المدفوع</li>
         <li>رقم التليفون الذي تم التحويل منه</li>
         <br/>
         <h5>اثبات التحويل:</h5>
         <li>إيصال السداد من فوري ورقم المعاملة</li>
         <li>سكرين شوت بالتحويل في حالة التحويل من خط فودافون</li>
         <li>سكرين شوت من تأكيد التحويل في حالة الدفع ببطاقات الائتمان</li>
        <br/>

        <h2>في حالة عدم ارسال هذه البيانات نأسف لعدم تمكننا من تأكيد دفع الاشتراك.</h2>
        <br/>
        </span>  
        <span style={{textAlign: 'right'}}>
        <li dir="rtl"> اخر موعد للدفع بعد ٣ أيام من تاريخ التسجيل.</li>
        </span>  
        <span style={{textAlign: 'right'}}>
        <li dir="rtl">تبدأ المحاضرات يومياً في مواعيد الدورة المعلنة الساعة ٧،٠٠ مساء بواقع محاضرتين يوميا بينهم استراحة قصيرة
        </li>
        </span>  
        <span style={{textAlign: 'right'}}>
        <li dir="rtl">المحاضرات بالحضور بالكنيسة ويمكن الحضور اونلاين على موقع دورة المخطوبين والدخول من خلال اسم المستخدم وكلمة المرور الذين تم ارسالهم سابقاً.
        </li>
        </span>  
        <span style={{textAlign: 'right'}}>
        <li dir="rtl">في حالة الحضور اونلاين لا يتم اثبات الحضور الا بعد مشاهدة المحاضرة كاملة ويرجى عدم اغلاق المتصفح او غلق الصوت حتى يتمكن النظام من اثبات الحضور.
        </li>
        </span>  
        <span style={{textAlign: 'right'}}>
        <li dir="rtl"> بعد الحضور سواء بالكنيسة او اونلاين سيتم اتاحة الفرصة لك لتقوم بحل الامتحان الخاص بكل محاضرة على موقع دورة المخطوبين ونتيجته تكون اما نجاح او رسوب ويمكن في حالة الرسوب إعادة مشاهدة المحاضرة وإعادة حل الامتحان الا محاضرات اليوم الأخير لا يمكن اعادتهم.
        </li>
        </span>  
        <span style={{textAlign: 'right'}}>
        <li dir="rtl">لتحصل على الشهادة الخاصة بالدورة يجب حضور المحاضرات كاملة والنجاح في كل الامتحانات.
        </li>
        </span>  
        <span style={{textAlign: 'right'}}>
        <li dir="rtl">يغلق باب تسجيل الأسماء بالكنيسة بعد بدء المحاضرة ب ١٠ دقائق على اقصى حد.       
        </li>
         </span>  
        <span style={{textAlign: 'right'}}>
        <li dir="rtl">فور طباعة الشهادات يتم ارسال رسالة بموعد استلامها من سكرتارية الاباء بالكنيسة.
        </li>
        </span>  
        <span style={{textAlign: 'right'}}><li dir="rtl">
         لأي استفسارات أخرى التواصل من خلال رسائل الواتس اب فقط على الرقم: <span dir="ltr"> &nbsp;  01019966994 </span> او رسائل البريد الالكتروني على <span dir="ltr"> &nbsp;  engaged@stmarkos.org </span> 
والرب يبارك حياتكم و يملأها بكل فرح و سرور       
</li>
 </span>  


   

      </div>
    </div>
  );
};

export default Payment;
