import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";
import axios from '../api/index';
import { useHistory } from "react-router-dom";
import 'react-phone-number-input/style.css'
import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl, isValidPhoneNumber , isPossiblePhoneNumber } from 'react-phone-number-input/'


const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        !هذا الحقل مطلوب
      </div>
    );
  }
};
const validEmail = (value) => {
  if (!isEmail(value)) {
    return (
      <div className="alert alert-danger" role="alert">
        هذا البريد الالكتروني غير صحيح!
      </div>
    );
  }
};

const vid = (value) => {
  if (value.length != 14) {
    return (
      <div className="alert alert-danger" role="alert">
        من فضلك ادخل الرقم القومي بطريقة صحيحة.
      </div>
    );
  }
  else {
    // axios({
    //   url: '/registration/checkid',
    //   body: {
    //     rakamKawmy: value,
    //   },
    //   headers: {
    //     //'Content-Type': 'multipart/form-data',
    //     //'x-access-token': userToken,
    //   },
    //   method: 'POST',
    // })
    //   .then(res => {
    //     console.log('Already taken, ', res);
    //     return (
    //       <div className="alert alert-danger" role="alert">
    //         Already taken
    //       </div>
    //     );

    //   })
    //   .catch(err => {

    //     console.warn('Failed to load chapter verses', err);
    //     return (
    //       <div className="alert alert-danger" role="alert">
    //       Already taken error
    //       </div>
    //     );
    //   });

  }
};

const vmobile = (value) => {
let result = value.substring(0, 3);



if (result == "+20"){
  if(value.length != 13 ){
    return false
  }

}
return isValidPhoneNumber(value);



  // if (true) {


  //   return alert(" من فضلك ادخل رقم المحمول بطريقة صحيحة.");
  //   // return (
      
  //   //   <div className="alert alert-danger" role="alert">
  //   //     من فضلك ادخل رقم المحمول بطريقة صحيحة.
  //   //   </div>
  //   // );
  // }
};

const vgender = (value) => {
  console.log('genderrrrrr', value);
  /*if (value.length !== 11) {
    return (
      <div className="alert alert-danger" role="alert">
      من فضلك ادخل رقم المحمول بطريقة صحيحة.
      </div>
    );
  }*/
};

const vname = (value) => {
  if (value.length < 5) {
    return (
      <div className="alert alert-danger" role="alert">
        من فضلك اتخل الاسم بطريقة صحيح.
      </div>
    );
  }
};


const Register = () => {
  //basics
  const form = useRef();
  const checkBtn = useRef();
  const [single, setSingle] = useState("");
  const [error, setMessage] = useState("");
  const [sucess, setSucess] = useState("");
  const [loading, setLoading] = useState(false);


  const [name, setName] = useState("");
  const [id, setId] = useState("");
  const [bdate, setBdate] = useState("");
  const [father, setFather] = useState("");
  const [knowus, setKnowus] = useState("");
  const [fatherchurch, setFatherchurch] = useState("");
  const [gender, setGender] = useState("");
  const [address, setAddress] = useState("");
  const [addressnow, setAddressnow] = useState("");
  const [church, setChurch] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [accept, setAccept] = useState(false);
  const [taccept, settAccept] = useState(false);
  //second one
  const [tname, settName] = useState("");
  const [tid, settId] = useState("");
  const [tbdate, settBdate] = useState("");
  const [tfather, settFather] = useState("");
  const [tknowus, settKnowus] = useState("");
  const [tfatherchurch, settFatherchurch] = useState("");
  const [tgender, settGender] = useState("");
  const [taddress, settAddress] = useState("");
  const [taddressnow, settAddressnow] = useState("");
  const [tchurch, settChurch] = useState("");
  const [tmobile, settMobile] = useState("");
  const [temail, settEmail] = useState("");

  //files upload
  const [fileone, setFileone] = useState("");
  const [filetwo, setFiletwo] = useState("");
  const [filethree, setFilethree] = useState("");
  const [tfileone, settFileone] = useState("");
  const [tfiletwo, settFiletwo] = useState("");
  const [tfilethree, settFilethree] = useState("");


  // const [password, setPassword] = useState("");
  const [successful, setSuccessful] = useState(false);

  const { message } = useSelector(state => state.message);
  //const dispatch = useDispatch();

  const onChangeSingle = (e) => {
    const single = e.target.value;
    setSingle(single);
  };
  // handle male

  const onChangeId = (e) => {
    const id = e.target.value;
    setId(id);
  };

  const onChangeBdate = (e) => {
    const bdate = e.target.value;
    setBdate(bdate);
  };

  const onChangeMobile = (e) => {
    const mobile = e;
    setMobile(mobile);
  };

  const onChangeGender = (e) => {
    console.log('hiiii gender');
    console.log(e.target.value);
    const gender = e.target.value;
    setGender(gender);
  };

  const onChangeName = (e) => {
    const name = e.target.value;
    setName(name);
  };

  const onChangeAddress = (e) => {
    const address = e.target.value;
    setAddress(address);
  };

  const onChangeAddressnow = (e) => {
    const addressnow = e.target.value;
    setAddressnow(addressnow);
  };

  const onChangeChurch = (e) => {
    const church = e.target.value;
    setChurch(church);
  };

  const onChangeFather = (e) => {
    const father = e.target.value;
    setFather(father);
  };

  const onChangeFatherchurch = (e) => {
    const fatherchurch = e.target.value;
    setFatherchurch(fatherchurch);
  };

  const onChangeKnowus = (e) => {
    const knowus = e.target.value;
    setKnowus(knowus);
  };


  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
  };

  //handle 2nd partner
  const onChangetId = (e) => {
    const tid = e.target.value;
    settId(tid);
  };

  const onChangetBdate = (e) => {
    const tbdate = e.target.value;
    settBdate(tbdate);
  };

  const onChangetMobile = (e) => {
    const tmobile = e;
    settMobile(tmobile);
  };

  const onChangetGender = (e) => {
    console.log('hiiii');
    console.log(e.target.value);
    const tgender = e.target.value;
    settGender(tgender);
  };

  const onChangetName = (e) => {
    const tname = e.target.value;
    settName(tname);
  };

  const onChangetAddress = (e) => {
    const taddress = e.target.value;
    settAddress(taddress);
  };

  const onChangetAddressnow = (e) => {
    const taddressnow = e.target.value;
    settAddressnow(taddressnow);
  };

  const onChangetChurch = (e) => {
    const tchurch = e.target.value;
    settChurch(tchurch);
  };

  const onChangetFather = (e) => {
    const tfather = e.target.value;
    settFather(tfather);
  };

  const onChangetFatherchurch = (e) => {
    const tfatherchurch = e.target.value;
    settFatherchurch(tfatherchurch);
  };

  const onChangetKnowus = (e) => {
    const tknowus = e.target.value;
    settKnowus(tknowus);
  };

  const onChangetEmail = (e) => {
    const temail = e.target.value;
    settEmail(temail);
  };

  const onChangeAccept = (e) => {
    setAccept(!accept);
  };

  const onChangetAccept = (e) => {
    settAccept(!taccept);
  };

  const onFileChangeone = event => {
    const selectedFile = event.target.files[0];
    setFileone(selectedFile);
  };
  const onFileChangetwo = event => {
    const selectedFile = event.target.files[0];
    setFiletwo(selectedFile);
  };
  const onFileChangethree = event => {
    const selectedFile = event.target.files[0];
    setFilethree(selectedFile);
  };

  const ontFileChangeone = event => {
    const selectedFile = event.target.files[0];
    settFileone(selectedFile);
  };
  const ontFileChangetwo = event => {
    const selectedFile = event.target.files[0];
    settFiletwo(selectedFile);
  };
  const ontFileChangethree = event => {
    const selectedFile = event.target.files[0];
    settFilethree(selectedFile);
  };
  const history = useHistory();

  const handleRegister = (props) => {
    setLoading(true);
    props.preventDefault();
    form.current.validateAll();
     if(!vmobile(mobile)){
      setMessage('من فضلك اكتب رقم الهاتف بشكل صحيح');
      setLoading(false);
      return;
     } 
     if (single === 'couple') {
    
      if(!vmobile(tmobile)){
      setMessage('من فضلك اكتب رقم الهاتف بشكل صحيح في بيانات الخطيبة');
      setLoading(false);
      return;
     } 
    
    }
    if (!accept) {
      setMessage('!من فضلك وافق علي الشروط والاحكام');
      setLoading(false);
      return;
    }
    console.log('here ana 3adet el 1st accept')

    const formData = new FormData();

    let sgender = 'male';
    if (gender) {
      sgender = gender
    }

    if (fileone !== "") {
      formData.append(
        "file1",
        fileone,
        'fileone'
      );
    }
    else {
      setMessage('!من فضلك اختر صورة البطاقة من الامام');
      setLoading(false);
      return;
    }

    if (filetwo !== "") {
      formData.append(
        "file2",
        filetwo,
        'filetwo'
      );
    }
    else {
      setMessage('!من فضلك اختر صورة البطاقة من الخلف');
      setLoading(false);
      return;
    }

    if (filethree !== "") {
      formData.append(
        "file3",
        filethree,
        'filethree'
      );
    }
    else {
      setMessage('!من فضلك اختر صورة شخصية ');
      setLoading(false);
      return;
    }
    console.log('done el 1st files');

    if (single === 'couple') {
      if (!taccept) {
        setMessage('!من فضلك وافق علي الشروط والاحكام');
        setLoading(false);
        return;
      }

      let tsgender = 'female';
      if (tgender) {
        tsgender = tgender
      }

      if (tfileone !== "") {
        formData.append(
          "file4",
          tfileone,
          'file4'
        );
      }
      else {
        setMessage('!من فضلك اختر صورة البطاقة من الامام');
        setLoading(false);
        return;
      }

      if (tfiletwo !== "") {
        formData.append(
          "file5",
          tfiletwo,
          'file5'
        );
      }
      else {
        setMessage('!من فضلك اختر صورة البطاقة من الخلف');
        setLoading(false);
        return;
      }

      if (tfilethree !== "") {
        formData.append(
          "file6",
          tfilethree,
          'file6'
        );
      }
      else {
        setMessage('!من فضلك اختر صورة شخصية ');
        setLoading(false);
        return;
      }
      console.log('done el 2nd files');
      if (
        id === "" ||
        name === "" ||
        mobile === "" ||
        mobile === "" ||
        email === "" ||
        bdate === "" ||
        address === "" ||
        addressnow === "" ||
        church === "" ||
        father === "" ||
        fatherchurch === "" ||
        knowus === "" ||
        tid === "" ||
        tname === "" ||
        tmobile === "" ||
        tmobile === "" ||
        temail === "" ||
        tbdate === "" ||
        taddress === "" ||
        taddressnow === "" ||
        tchurch === "" ||
        tfather === "" ||
        tfatherchurch === "" ||
        tknowus === ""
      ) {
        setMessage('!من فضلك ادخل كل البيانات بشكل صحيح');
        setLoading(false);
        return;
      }
      console.log('done el 2st data');
      console.log(sgender);
      console.log(tsgender);
      let couple = [
        {
          rakamKawmy: id,
          fullName: name,
          mobile: mobile,
          email: email,
          bdate: bdate,
          gender: sgender,
          address: address,
          addressnow: addressnow,
          church: church,
          father: father,
          fatherchurch: fatherchurch,
          knowus: knowus,

        },
        {
          rakamKawmy: tid,
          fullName: tname,
          mobile: tmobile,
          email: temail,
          bdate: tbdate,
          gender: tsgender,
          address: taddress,
          addressnow: taddressnow,
          church: tchurch,
          father: tfather,
          fatherchurch: tfatherchurch,
          knowus: tknowus,

        }
      ];
      formData.append('persons', JSON.stringify(couple))
    }
    else {
      if (
        id === "" ||
        name === "" ||
        mobile === "" ||
        mobile === "" ||
        email === "" ||
        bdate === "" ||
        address === "" ||
        addressnow === "" ||
        church === "" ||
        father === "" ||
        fatherchurch === "" ||
        knowus === ""
      ) {
        setMessage('!من فضلك ادخل كل البيانات بشكل صحيح');
        setLoading(false);
        return;
      }
      console.log('done el 1st data');
      let couple = [
        {
          rakamKawmy: id,
          fullName: name,
          mobile: mobile,
          email: email,
          bdate: bdate,
          gender: sgender,
          address: address,
          addressnow: addressnow,
          church: church,
          father: father,
          fatherchurch: fatherchurch,
          knowus: knowus,

        }
      ];
      formData.append('persons', JSON.stringify(couple))
    }
    console.log('done kolo w da5el 3al axios', formData);
    setSucess('...جاري تحميل الصور');
    return axios({
      url: '/api/registration',
      body: formData,
      headers: {},
      method: 'POST',
    })
      .then(res => {
        console.log('here ana gebt el results, ', res);
        console.log('status, ', res.status);
        if (res.status === 200) {
          setLoading(false);
          history.push("/payment");
          // window.location.reload();
        }
        else if (res.status === 403) {
          setLoading(false);
          setMessage('!لقد تم اضافة هذا الرقم القومي من قبل');
        }
        else {
          setMessage('!من فضلك ادخل البيانات بشكل صحيح');
          setLoading(false);
        }
      })
      .catch(err => {
        console.log('here error', err);
        if (err.response.status === 403) {
          setLoading(false);
          setMessage('!لقد تم اضافة هذا الرقم القومي من قبل');
        }
        else {
          setLoading(false);
          setMessage('!من فضلك ادخل البيانات بشكل صحيح');
        }
        console.log('here error', err.response.status);
        return;
      });
  };

  return (
    <div className="jumbotron text-center container">
      <div className="jumbotron text-center">

        <Form onSubmit={handleRegister} ref={form}>
          {!successful && (
            <div>
              <div>
                <div>:ساحضر هذة الدورة</div>
                <select
                  onChange={onChangeSingle}
                  validations={[required, vgender]}
                >
                  <option value="single">بمفردي</option>
                  <option value="couple">انا وخطيبي/خطيبتي</option>
                </select>
              </div>
              <br />
              <br />

              <div hidden={single !== 'couple'} className="text-right">
                <label>:بيانات الخطيب</label>
              </div>

              <div className="form-group">
                <label htmlFor="id">برجاء ادخال الرقم القومي كاملاً (ال ١٤ رقم) برجاء كتابة الارقام (فقط)  باللغة الانجليزية</label>
                <Input
                  type="text"
                  className="form-control"
                  name="id"
                  value={id}
                  onChange={onChangeId}
                  validations={[required, vid]}
                />
              </div>

              <div className="form-group">
                <label htmlFor="name">الاسم كاملاً باللغة العربية كما هو ببطاقة الرقم القومى</label>
                <Input
                  type="text"
                  className="form-control"
                  name="name"
                  value={name}
                  onChange={onChangeName}
                  validations={[required, vname]}
                />
              </div>

              <div>
                <div>نوع:</div>
                <select
                  onChange={onChangeGender}
                  validations={[required, vgender]}
                >
                  <option selected value="male">ذكر</option>
                  <option value="female">انثي</option>
                </select>
              </div>

              <div className="form-group">
                <label htmlFor="mobile">(whatsapp) رقم المحمول  به واتساب</label>

        
                <PhoneInput
                  placeholder="Enter phone number"
                  value={mobile}
                  name="mobile"
                  defaultCountry="EG"
                  onChange={onChangeMobile}
                  rules={{
                    validate: (value) => vmobile(value)
             }}
                  error={mobile ? ( vmobile ? undefined : 'Invalid phone number') : 'Phone number required'} />
                 phone: {mobile && formatPhoneNumberIntl(mobile)}                 
               
              </div>

              <div className="form-group">
                <label htmlFor="mobile">تاريخ الميلاد</label>
                <Input
                  type="date"
                  className="form-control"
                  name="bdate"
                  value={bdate}
                  onChange={onChangeBdate}
                  validations={[required]}
                />
             
              </div>

              <div className="form-group">
                <label htmlFor="email">البريد الالكتروني</label>
                <Input
                  type="text"
                  className="form-control"
                  name="email"
                  value={email}
                  onChange={onChangeEmail}
                  validations={[required, validEmail]}
                />
              </div>

              <div className="form-group">
                <label htmlFor="address">العنوان بالرقم القومي بالتفصيل</label>
                <Input
                  type="text"
                  className="form-control"
                  name="address"
                  value={address}
                  onChange={onChangeAddress}
                  validations={[required]}
                />
              </div>

              <div className="form-group">
                <label htmlFor="addressnow">العنوان الحالي بالتفصيل</label>
                <Input
                  type="text"
                  className="form-control"
                  name="addressnow"
                  value={addressnow}
                  onChange={onChangeAddressnow}
                  validations={[required]}
                />
              </div>

              <div className="form-group">
                <label htmlFor="church">(الكنيسة التابع لها (اسم الكنيسة و المنطقة/الحي</label>
                <Input
                  type="text"
                  className="form-control"
                  name="church"
                  value={church}
                  onChange={onChangeChurch}
                  validations={[required]}
                />
              </div>

              <div className="form-group">
                <label htmlFor="father">اب الاعتراف</label>
                <Input
                  type="text"
                  className="form-control"
                  name="father"
                  value={father}
                  onChange={onChangeFather}
                  validations={[required]}
                />
              </div>

              <div className="form-group">
                <label htmlFor="fatherchurch">كنيسة اب الاعتراف</label>
                <Input
                  type="text"
                  className="form-control"
                  name="fatherchurch"
                  value={fatherchurch}
                  onChange={onChangeFatherchurch}
                  validations={[required]}
                />
              </div>

              <div className="form-group">
                <label htmlFor="knowus">كيف عرفت بالدورة ال ٥٣ للمخطوبين</label>
                <Input
                  type="text"
                  className="form-control"
                  name="knowus"
                  value={knowus}
                  onChange={onChangeKnowus}
                  validations={[required]}
                />
              </div>

              <div className="form-group">
                <label htmlFor="tfatherchurch">(برجاء تحميل صورة واضحة لبطاقة الرقم القومى (وجه</label>
                <input type="file" onChange={onFileChangeone} />
              </div>

              <div className="form-group">
                <label htmlFor="tfatherchurch">(تحميل صورة واضحة لبطاقة الرقم القومى (ظهر</label>
                <input type="file" onChange={onFileChangetwo} />
              </div>

              <div className="form-group">
                <label htmlFor="tfatherchurch">برجاء تحميل صورة شخصية</label>
                <input type="file" onChange={onFileChangethree} />
              </div>

              <div>
                <span>
                  اقر بان كافة البيانات المسجلة من خلالي صحيحة، و في حالة تسجيلها خاطئة لا يتم قبول الاشتراك بالدورة كما انه ليس لي الحق في المطالبة بشهادة اتمام حضور الدورة الا في حالة حضوري لجميع المحاضرات
                </span>
                <input
                  style={{ margin: 10 }}
                  type="checkbox"
                  onChange={onChangeAccept}
                />
              </div>

              <div hidden={single !== 'couple'}>
                <div className="text-right">
                  <label>:بيانات الخطيبة</label>
                </div>
                <div className="form-group">
                  <label htmlFor="tid">برجاء ادخال الرقم القومي كاملاً (ال ١٤ رقم) برجاء كتابة الارقاsم (فقط)  باللغة الانجليزية</label>
                  <Input
                    type="text"
                    className="form-control"
                    name="tid"
                    value={tid}
                    onChange={onChangetId}
                    validations={[required, vid]}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="tname">الاسم كاملاً باللغة العربية كما هو ببطاقة الرقم القومى</label>
                  <Input
                    type="text"
                    className="form-control"
                    name="tname"
                    value={tname}
                    onChange={onChangetName}
                    validations={[required, vname]}
                  />
                </div>

                <div>
                  <div>نوع:</div>
                  <select
                    onChange={onChangetGender}
                    validations={[required, vgender]}
                  >
                    <option selected value="female">انثي</option>
                    <option value="male">ذكر</option>
                  </select>
                </div>

                <div className="form-group">
                <label htmlFor="mobile">(whatsapp) رقم المحمول  به واتساب</label>

                <PhoneInput
                  placeholder="Enter phone number"
                  value={tmobile}
                  name="tmobile"
                  defaultCountry="EG"
                  onChange={onChangetMobile}
                  rules={{
                    validate: (value) => vmobile(value)
             }}
                  error={tmobile ? ( vmobile ? undefined : 'Invalid phone number') : 'Phone number required'} />
                 phone: {tmobile && formatPhoneNumberIntl(tmobile)}                 
               
              </div>

              
                <div className="form-group">
                  <label htmlFor="tbdate">تاريخ الميلاد</label>
                  <Input
                    type="date"
                    className="form-control"
                    name="tbdate"
                    value={tbdate}
                    onChange={onChangetBdate}
                    validations={[required]}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="temail">البريد الالكتروني</label>
                  <Input
                    type="text"
                    className="form-control"
                    name="temail"
                    value={temail}
                    onChange={onChangetEmail}
                    validations={[required, validEmail]}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="taddress">العنوان بالرقم القومي بالتفصيل</label>
                  <Input
                    type="text"
                    className="form-control"
                    name="taddress"
                    value={taddress}
                    onChange={onChangetAddress}
                    validations={[required]}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="taddressnow">العنوان الحالي بالتفصيل</label>
                  <Input
                    type="text"
                    className="form-control"
                    name="taddressnow"
                    value={taddressnow}
                    onChange={onChangetAddressnow}
                    validations={[required]}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="tchurch">(الكنيسة التابع لها (اسم الكنيسة و المنطقة/الحي</label>
                  <Input
                    type="text"
                    className="form-control"
                    name="tchurch"
                    value={tchurch}
                    onChange={onChangetChurch}
                    validations={[required]}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="tfather">اب الاعتراف</label>
                  <Input
                    type="text"
                    className="form-control"
                    name="tfather"
                    value={tfather}
                    onChange={onChangetFather}
                    validations={[required]}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="tfatherchurch">كنيسة اب الاعتراف</label>
                  <Input
                    type="text"
                    className="form-control"
                    name="tfatherchurch"
                    value={tfatherchurch}
                    onChange={onChangetFatherchurch}
                    validations={[required]}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="tknowus">كيف عرفت بالدورة ال ٥٣ للمخطوبين</label>
                  <Input
                    type="text"
                    className="form-control"
                    name="tknowus"
                    value={tknowus}
                    onChange={onChangetKnowus}
                    validations={[required]}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="tfatherchurch">(برجاء تحميل صورة واضحة لبطاقة الرقم القومى (وجه</label>
                  <input type="file" onChange={ontFileChangeone} />
                </div>

                <div className="form-group">
                  <label htmlFor="tfatherchurch">(تحميل صورة واضحة لبطاقة الرقم القومى (ظهر</label>
                  <input type="file" onChange={ontFileChangetwo} />
                </div>

                <div className="form-group">
                  <label htmlFor="tfatherchurch">برجاء تحميل صورة شخصية </label>
                  <input type="file" onChange={ontFileChangethree} />
                </div>

                <div>
                  <span>
                    اقر بان كافة البيانات المسجلة من خلالي صحيحة، و في حالة تسجيلها خاطئة لا يتم قبول الاشتراك بالدورة كما انه ليس لي الحق في المطالبة بشهادة اتمام حضور الدورة الا في حالة حضوري لجميع المحاضرات
                  </span>
                  <input
                    style={{ margin: 10 }}
                    type="checkbox"
                    onChange={onChangetAccept}
                  />
                </div>

              </div>

              <div className="form-group">
                <button className="btn btn-primary btn-block" disabled={loading}>
                  {loading && (
                    <span className="spinner-border spinner-border-sm"></span>
                  )}
                  <span>
                    تسجيل
                  </span>
                </button>
                <br />
                <span style={{ textAlign: 'center' }}>
                  <li dir="rtl">  اخر موعد للدفع بعد ٣ أيام من تاريخ التسجيل. (انظر طرق الدفع)</li>
                </span>
              </div>
            </div>
          )}

          {error && (
            <div className="form-group">
              <div className={successful ? "alert alert-success" : "alert alert-danger"} role="alert">
                {error}
              </div>
            </div>
          )}

          {sucess && (
            <div className="form-group">
              <div className={successful ? "alert alert-success" : "alert alert-success"} role="alert">
                {sucess}
              </div>
            </div>
          )}
          <CheckButton style={{ display: "none" }} ref={checkBtn} />
        </Form>
      </div>
    </div>
  );
};

export default Register;
