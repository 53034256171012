import React, { useEffect } from "react";
import { useDispatch,  } from "react-redux";
import { Router, Switch, Route, Link } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import axios from './api/index';
 import Login from "./components/Login";
import Register from "./components/Register";
import Home from "./components/Home";
import Profile from "./components/Profile";
import Quiz from "./components/QuizScreen";
import Session from "./components/SessionScreen";
import Payment from "./components/Payment";

import { logout } from "./actions/auth";
import { clearMessage } from "./actions/message";

import { history } from "./helpers/history";

const App = () => {
  const tokenString = sessionStorage.getItem('token');
  const userToken = JSON.parse(tokenString);

  const userString = sessionStorage.getItem('user');
  let currentUser = JSON.parse(userString);

  const dispatch = useDispatch();

  useEffect(() => {
    history.listen((location) => {
      dispatch(clearMessage()); 
    });
  }, [dispatch]);

  useEffect(() => {
    if (currentUser) {
      
    }
  }, [currentUser]);

  const logOut = () => {
    return axios({
      url: `/api/logout`,
      method: 'POST',
  })
  .then(res => {
      currentUser = false;
      dispatch(logout());
      sessionStorage.clear();
      window.location.reload();
      
  })
  .catch(err => {
  });
  };

  if (!currentUser) {
    currentUser = false;
    localStorage.removeItem("user");
    history.push("/home");
    sessionStorage.clear();
  }
  if (!userToken) {
    currentUser = false;
    localStorage.removeItem("user");
    history.push("/home");
    sessionStorage.clear();
  }

  return (
    <Router history={history}>
      <div>
        <nav className="navbar navbar-expand navbar-dark bg-dark">
          <Link to={"/"} className="navbar-brand">
            دورة المخطوبين
          </Link>
          {currentUser &&  currentUser.name && (
          <li className="navbar-brand">
            {currentUser.name}
          </li>
          )}
          <div className="navbar-nav mr-auto">
          
         

            {/* {currentUser && (
              <li className="nav-item">
                <Link to={"/user"} className="nav-link">
                  User
                </Link>
              </li>
            )} */}
          </div>

          {currentUser ? (
            <div className="navbar-nav ml-auto">
              <li>
                {/* will redirect to user detials page */}
                <Link to={"/profile"} className="nav-link" >
                المحاضرات
                </Link>
              </li>
              <li className="nav-item">
                {/* <a href="/home" className="nav-link" onClick={logOut}>
                  LogOut
                </a> */}
                 <Link to={"/home"} className="nav-link" onClick={logOut}>
                  تسجيل الخروج
                </Link>
              </li>
            </div>
          ) : (
            <div className="navbar-nav ml-auto">
              <li className="nav-item">
                <Link to={"/login"} className="nav-link">
                  تسجيل الدخول
                </Link>
              </li>

              <li className="nav-item">
                <Link to={"/register"} className="nav-link">
                تسجيل جديد
                </Link>
              </li>
              <li className="nav-item">
                <Link to={"/payment"} className="nav-link">
                طرق الدفع
                </Link>
              </li>
            </div>
          )}
        </nav>

        <div className="">
          <Switch>
            <Route exact path={["/", "/home"]} component={Home} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/register" component={Register} />
            <Route exact path="/profile" component={Profile} />
            <Route exact path="/session/:id" component={Session} />
            <Route exact path="/quiz" component={Quiz} />
            <Route exact path="/payment" component={Payment} >
              <Payment/>
              </Route>
          </Switch>
        </div>
      </div>
    </Router>
  );
};

export default App;
